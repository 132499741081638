.popUp-container {
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: auto;
}

.popUp-window {
    overflow-y: auto;
    background-color: white;
    box-shadow: 0px 0px 16px rgba(119, 161, 199, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 4px;
    z-index: 1000;
    max-height: calc(100vh - 40px);
}

.popUp-no-padding {
    padding: 20px 0 20px 0;
}

.popUp-no-padding-all {
    padding: 0;
}

.contentBased {
}

.small {
    width: 35vw;
    padding-bottom: 30px;
}

.mediumSmall {
    width: 45vw;
    padding-bottom: 30px;
}

.medium {
    width: 70vw;
    padding-bottom: 30px;
}

.big {
    width: 780px;
    padding-bottom: 30px;
}

.hide {
    display: none;
}

@media screen and (max-width: 1000px) {
    .medium {
        width: 90vw;
    }

    .big {
        width: 90vw;
    }

    .small {
        width: 70vw;
    }
}

@media screen and (max-width: 400px) {
    .medium {
        width: 99vw;
    }
}

.popUp-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    gap: 9px;
}

.popUp-title {
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.popUp-title-container {
    display: flex;
    align-items: center;
}

.popUp-title-icon {
    margin-right: 9px;
    display: flex;
    align-items: center;
}

.pop-up-close-icon {
    cursor: pointer;
}

.popUp-message {
    flex: 1;
}

.popUp-OkBtn {
    align-self: flex-end;
    margin-top: 40px;
    margin-bottom: 10px;
}
