/* .home-section {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.section-hero-container {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 90px;
    padding: 70px 0px 70px 0px;
    align-items: center;
    flex: 1 0 0;
    background: url(../assets/img/grey-background.svg) no-repeat;
    background-color: #f5f5f5;
    background-position: right top;
    background-position-y: -1px;
    margin-left: 160px;
    /* justify-content: center; */
}

.section-hero-left {
    /* padding-right: 90px; */
    max-width: 650px;
}

.home-hero-title {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
}

.home-hero-title ul {
    list-style-position: inside;
    list-style-type: disc;
    margin-top: 12px;
    line-height: 48px;
}

.home-hero-title ul span {
    margin-left: 54px;
}

.home-hero-subtitle {
    color: var(--Gray-Dark-Medium, #3e404d);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 40px;
    margin-bottom: 80px;
}

.indented {
    text-indent: -1.4em;
    padding-left: 1em;
}

.hero-image {
    max-width: 78vw;
}

@media (max-width: 1400px) {
    .section-hero-container {
        flex-wrap: wrap-reverse;
        margin-left: 0;
        justify-content: center;
        /* max-width: min-content; */
    }

    .section-hero-left {
        padding-left: 30px;
    }
}

@media (max-width: 680px) {
    .section-hero-container {
        flex-wrap: wrap-reverse;
        /* margin-left: 0ś;
        justify-content: center; */
        /* max-width: min-content; */
    }

    .section-hero-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .home-hero-title {
        font-size: 24px;
        align-self: flex-start;
    }

    .home-hero-title ul {
        line-height: 32px;
        margin-top: 8px;
    }
}
